<template>
  <v-container class="fill-height" fluid data-cy="impersonate-page">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <Impersonate v-if="authMethod == 'internal'" />
        <ImpersonateCas v-if="authMethod == 'CAS3'" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Impersonate from "@/modules/auth/components/Impersonate.vue";
import ImpersonateCas from "@/modules/auth/components/ImpersonateCas.vue";

export default {
  name: "ImpersonatePage",
  components: {
    Impersonate,
    ImpersonateCas,
  },
  computed: {
    authMethod() {
      return this.$store.getters["config/getAuthMethod"];
    },
  },
};
</script>
