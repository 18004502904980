<template>
  <v-card data-cy="impersonate-comp">
    <v-card-text>
      <div>{{ $t("auth.impersonations") }}</div>
      <div>
        {{ $t("auth.infoCas3Impersonate") }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text small :to="loginRoute">{{ $t("auth.backToLogin") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "ImpersonateCas",
  mixins: [ComponentStatus],
  data: () => ({}),
  computed: {
    loginRoute() {
      let route = {
        name: "login",
      };
      if (this.organisationIdToken) {
        route.query = { oi: this.organisationIdToken };
      }

      return route;
    },
  },
};
</script>
